// ** React Imports
import React, { useState, useEffect } from "react";

// ** MUI Components
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled, useTheme } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";

// ** Icons Imports
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useNavigate, useParams } from "react-router-dom";
import { SignupWithGoogle, WriteUserData } from "../../API/firebase.config";
import Swal from "sweetalert2";
import {
  EmailSignup,
  Login,
  ResendOtp,
  VerifyOTP,
} from "../../API/api-endpoint";
import Background from "../../Images/Background.png";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { FormHelperText, FormLabel, Grid } from "@mui/material";
import GoogleImage from "../../Images/GoogleImage.png";
import LayoutImage from "./LayoutImage";
import SlickCarousel from "../SlickCarousel";

// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: "28rem" },
}));

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
  },
}));

const SignupPage = (props) => {
  let isVerifyOTP = localStorage.getItem("isOTP")
  const [enteredOTP, setenteredOTP] = useState("");
  const [isOTPScreen, setisOTPScreen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);



  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleSigninLoading, setIsGoogleSigninLoading] = useState(false);

  useEffect(() => {
    if (isVerifyOTP) {
      setisOTPScreen(true)
    }
  }, [isVerifyOTP]);
  useEffect(()=>{
    localStorage.clear()

  },[])

  // ** Hook
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [spacing, setSpacing] = useState(window.innerWidth < 500 ? 0 : 2);

  useEffect(() => {
    const handleResize = () => {
      setSpacing(window.innerWidth < 500 ? 0 : 2);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const ResendAPI = async () => {
    setenteredOTP("")
    let apicalled = false;
    setisOTPScreen(true);
    let email = localStorage.getItem("email");
    if (apicalled === false) {
      let response = await ResendOtp(email);
      if (response.status) {
        apicalled = true;
        Swal.fire({
          title: "Success",
          text: response?.message,
          icon: "success",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: response?.message,
          icon: "error",
        });
      }
    }
  };

  // ... other code ...

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
    },
    validate: (values) => {
      const errors = {};

      const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
const ipInBracketsRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@\[(\d{1,3}\.){3}\d{1,3}\]$/;
const invalidCharactersRegex = /[&(),:;<>@[\]\\]/; // Disallow characters like &, (), : etc.
const consecutiveDotsRegex = /\.{2,}/; // Disallow consecutive dots

const isValidEmail = (email) => {
  if (ipInBracketsRegex.test(email)) {
    return true; // Valid if it's an IP in brackets
  }
  if (!emailRegex.test(email)) {
    return false;
  }

  const [localPart, domainPart] = email.split('@');

  if (
    invalidCharactersRegex.test(localPart) || 
    consecutiveDotsRegex.test(localPart) || 
    consecutiveDotsRegex.test(domainPart)
  ) {
    return false;
  }

  return true;
};

// Validation check for email field
if (!values.email) {
  errors.email = "Email is required";
} else if (!isValidEmail(values.email)) {
  errors.email = "Invalid email address";
}

      if (!values.password) {
        errors.password = "Password is required";
      } else if (
        !/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_])\S{8,}$/.test(
          values.password
        )
      ) {
        errors.password =
          "Password must be at least 8 characters long and contain a character, number, and a special character without spaces";
      }

      if (!values.firstName) {
        errors.firstName = "First Name is required";
      } else if (!/^[A-Z][a-z]*$/.test(values.firstName)) {
        errors.firstName = "First Name should start only with an uppercase letter and not contain numbers";
      }
      else if (values.firstName?.length < 3) {
        errors.firstName = "First Name must be at least 3 characters long"
      }
      else if (values.firstName?.length > 20) {
        errors.firstName = "First Name must be less than 20 characters"
      }
      else if (values.firstName?.length > 20) {
        errors.firstName = "First Name must be less than 20 characters"
      }
      if (!values.lastName) {
        errors.lastName = "Last Name is required";
      }
      else if (values.lastName?.length < 1) {
        errors.lastName = "Last Name must be at least 1 characters long"
      }
      else if (values.lastName?.length > 20) {
        errors.lastName = "Last Name must be less than 20 characters"
      }
      else if (!/^[A-Za-z]+$/.test(values.lastName)) {
        errors.lastName = "Last Name should only contain letters";}

      return errors;
    },
    onSubmit: async (values) => {
      // Your form submission logic here
      if (formik.isValid) {
        setIsLoading(true);
        let name = `${formik.values.firstName} ${formik.values.lastName}`
        let result = await EmailSignup({
          email: values.email,
          password: values.password,
          firstname: values.firstName,
          lastname: values.lastName,
          role: 5,
        }, dispatch, name);
        console.log("result", result.status);
        if (result.status) {
          Swal.fire({
            title: "Success",
            text: result?.message,
            icon: "success",
          });
          setIsLoading(false);
          // navigate("/");
          localStorage.setItem("isOTP", true)
          setisOTPScreen(true);
          localStorage.setItem(
            "fullname",
            `${formik.values.firstName} ${formik.values.lastName}`
          );
          // await WriteUserData(
          //   result.firebaseUser?.uid,
          //   result.firebaseUser?.displayName,
          //   result.firebaseUser?.email,
          //   "",
          //   dispatch
          // );

        } else {
          Swal.fire({
            title: "Error!!",
            text: result.message,
            icon: "error",
          });
          setIsLoading(false);
        }
      }
    },
  });

  const handleGoogleSignin = async () => {
    setIsGoogleSigninLoading(true);
    setIsGoogleSigninLoading(false);

    let result = await SignupWithGoogle(dispatch);
    console.log("result", result);
    if (result.success == true) {
      Swal.fire({
        title: "Success",
        text: result?.message,
        icon: "success",
      });
      navigate("/phone-otp-verification");
    } else {
      if (result.message) {
        Swal.fire({
          title: "Error!!",
          text: result.message,
          icon: "error",
        });
      }

    }
    setIsGoogleSigninLoading(false);
  };

  const verifyOtp = async () => {
   let userToken = localStorage.getItem('userTokentoken')
    if (enteredOTP.length === 6) {
      setIsLoading(true);
      try {
        let FinalEmail =
          formik.values.email.length === 0
            ? localStorage.getItem("email")
            : formik.values.email;
        let result = await VerifyOTP({
          email: FinalEmail,
          otp: enteredOTP,
        });
        if (result.message == "OTP verified successfully") {
          Swal.fire({
            title: "Success!!",
            text: "Successfully verified Your Email",
            icon: "success",
          });
    localStorage.setItem("token", userToken);
    localStorage.removeItem("userTokentoken");

          navigate("/phone-otp-verification");
          localStorage.removeItem("isOTP")

          //navigate("/onboarding-flow");
          setIsLoading(false);
        } else if (result.message === "Invalid OTP") {
          Swal.fire({
            title: "Error!!",
            text: result.message,
            icon: "error",
          });
          setIsLoading(false);
        }
      } catch (error) {
        Swal.fire({
          title: "Error!!",
          text: "Entered OTP is incorrect",
          icon: "error",
        });
        setIsLoading(false);
      }
    } else {
      Swal.fire({
        title: "Error!!",
        text: "Please enter the 6 digit only",
        icon: "error",
      });
    }
  };

  // ... rest of your code ...
  if (!isOTPScreen) {
    return (
      <>
        <Box
          className="content-center heightAuto"
          style={{
            width: "100vw",
            padding: "40px",
            height: "100vh", // 100% of the viewport height
          }}
        >
          <Grid container>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={7} style={{
                display: "flex",
                padding: "20px",
                alignItems: "center"
                // justifyContent: "center",

              }}>
                <div style={{ width: "100%" }}>
                  <div >
                    <div className="headingCont  mb-2">
                      <Typography variant="h4" className="heading">Sign<span style={{ color: "orange" }}> Up</span></Typography>{" "}
                    </div>
                    <form autoComplete="off" onSubmit={formik.handleSubmit} className="form1">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <FormLabel sx={{ fontSize: "14px", fontWeight: "600", color: "background: rgba(13, 20, 28, 1)", marginBottom: "10px" }} >Email<span className="text-[red]">*</span></FormLabel>
                          <TextField
                            fullWidth
                            size="small"
                            // id="email"
                            className="mb0"
                            placeholder="Email"
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && formik.errors.email}
                            helperText={formik.touched.email && formik.errors.email}
                            sx={{ marginBottom: 2 }}
                            {...formik.getFieldProps("email")}
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormLabel sx={{ fontSize: "14px", fontWeight: "600", color: "background: rgba(13, 20, 28, 1)", marginBottom: "10px" }} >First Name<span className="text-[red]">*</span></FormLabel>
                          <TextField
                            fullWidth
                            id="first Name"
                            placeholder="First Name"
                            className="mb0"
                            size="small"
                            onBlur={formik.handleBlur}
                            error={formik.touched.firstName && formik.errors.firstName}
                            helperText={
                              formik.touched.firstName && formik.errors.firstName
                            }
                            sx={{ marginBottom: 2 }}
                            {...formik.getFieldProps("firstName")}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormLabel sx={{ fontSize: "14px", fontWeight: "600", color: "background: rgba(13, 20, 28, 1)", marginBottom: "10px" }} >Last Name<span className="text-[red]">*</span></FormLabel>
                          <TextField
                            fullWidth
                            id="last Name"
                            size="small"
                            onBlur={formik.handleBlur}
                            placeholder="Last Name"
                            className="mb0"
                            error={formik.touched.lastName && formik.errors.lastName}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                            sx={{ marginBottom: 2 }}
                            {...formik.getFieldProps("lastName")}
                          />
                        </Grid>
                       
                        <Grid className="mt5" item xs={12} sm={12}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontSize: "14px", fontWeight: "600", color: "background: rgba(13, 20, 28, 1)" }} >Password<span className="text-[red]">*</span></FormLabel>
                            <OutlinedInput
                              value={formik.values.password}
                              id="auth-login-password"
                              name="password"
                              size="small"
                              onBlur={formik.handleBlur}
                            placeholder="Password"
                            
                              error={formik.touched.password && formik.errors.password}
                              // helperText={formik.touched.password && formik.errors.password}
                              onChange={formik.handleChange}
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <InputAdornment
                                  style={{ marginRight: "15px" }}
                                  position="end"
                                >
                                  <IconButton
                                    edge="end"
                                    onClick={() => setShowPassword(!showPassword)}
                                    aria-label="toggle password visibility"
                                  >
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            <FormHelperText style={{ color: "#d32f2f" }}>
                              {formik.touched.password && formik.errors.password}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <LoadingButton
                            fullWidth
                            size="large"
                            className="mb0"
                            variant="contained"
                            sx={{
                              marginBottom: 2,
                              color: "white",
                              fontSize: "14px",
                              backgroundColor: "#FFA654",
                              '&:hover': {
                                backgroundColor: '#FFA654', // Change to your desired hover color
                              },

                            }}
                            type="submit"
                            // disabled={!formik.isValid}
                            loading={isLoading}
                            loadingPosition="start"
                          >
                            Signup
                          </LoadingButton>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            sx={{
                              color: "white",
                              backgroundColor: "#FFA654",
                              '&:hover': {
                                backgroundColor: '#FFA654', // Change to your desired hover color
                              },

                            }}
                            onClick={() => handleGoogleSignin()}
                            loading={isGoogleSigninLoading}
                            loadingPosition="start"
                            startIcon={
                              <span style={{ display: 'flex', alignItems: 'center', fontSize: "14px", justifyContent: "center", textAlign: "center" }}>
                                <img src={GoogleImage} alt="Image" style={{ marginRight: '8px' }} />
                                Sign up with Google
                              </span>
                            }
                          >
                          </LoadingButton>
                        </Grid>
                      </Grid>
                      <Typography className="mt10" variant="h6" component="div" style={{ fontSize: "16px", fontWeight: "500" }}>Already have an account?<span>
                        <a onClick={() => {
                          navigate("/")
                          localStorage.clear();
                          }} className="cursor-pointer text-[#E67E22]"> Sign in</a>
                      </span></Typography>
                    </form>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <SlickCarousel />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  } else {
    return (
      <div className="app__container">
        <Box
          className="content-center heightAuto"
          style={{
            width: "100vw",
            padding: "40px",
            height: "100vh", // 100% of the viewport height
          }}
        >
          <Grid container>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7} className="p0" style={{
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                padding: "50px",
                alignItems: "center"
              }}>
                <div style={{ width: "100%" }}>
                  <div className="form1">
                    <Box sx={{ marginBottom: 2, marginTop: 2 }}>
                      <Typography variant="h6" className="font16" component="div">
                        An OTP has been sent to your email, please enter it below to verify your email
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <FormLabel sx={{ fontSize: "14px", fontWeight: "600", color: "background: rgba(13, 20, 28, 1)", marginBottom: "10px" }} >OTP</FormLabel><br />
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          type="number"
                          value={enteredOTP}
                          onChange={(event) => setenteredOTP(event.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {isVerifyOTP &&
                          <LoadingButton
                            fullWidth
                            variant="contained"
                            sx={{
                              marginTop: "20px",
                              marginBottom: "10px",
                              // color: "white",
                              // backgroundColor: "rgb(145, 85, 253)",
                              color: "white",
                              backgroundColor: "#FFA654",
                              '&:hover': {
                                backgroundColor: '#FFA654', // Change to your desired hover color
                              },

                            }}
                            className="m0"
                            onClick={ResendAPI}
                            loading={isLoading}
                            loadingPosition="start"
                          >
                            Resend OTP
                          </LoadingButton>
                        }

                      </Grid>


                      <Grid item xs={12} sm={6}>
                        <LoadingButton
                        fullWidth
                        className="mt0"
                          variant="contained"
                          sx={{
                            marginBottom: "10px",
                            marginTop: "20px",
                            // color: "white",
                            // backgroundColor: "rgb(145, 85, 253)",
                            color: "white",
                            backgroundColor: "#FFA654",
                            '&:hover': {
                              backgroundColor: '#FFA654', // Change to your desired hover color
                            },

                          }}
                          onClick={verifyOtp}
                          loading={isLoading}
                          loadingPosition="start"
                        >
                          Verify Otp
                        </LoadingButton>
                      </Grid>


                    </Grid>
                    &nbsp;
                    <strong >
                      <a onClick={() => {

                        setisOTPScreen(false)
                        localStorage.clear()
                      }} className="cursor-pointer text-sm ">
                        Try with new Email
                      </a>
                    </strong>
                  </div>

                </div>
              </Grid>
              <Grid item xs={12} sm={5}>
                <SlickCarousel />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
};

export default SignupPage;
