import React from 'react'
import { Box } from '@mui/system'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useState } from 'react';
import { useFormik } from 'formik';
import { Autocomplete, Card, FormControlLabel, OutlinedInput, Radio, RadioGroup, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import {
  serverTimestamp,
  onValue,
  query,
  limitToLast,
  orderByKey,
  orderByChild,
  equalTo,
  ref,
  update,
  get,
  set,
  getDatabase,
  runTransaction
} from "firebase/database";
import { db } from '../API/firebase.config';
import { Button, CardContent, FormControl, FormLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReactQuill from "react-quill";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useEffect } from 'react';
import { DisconnectStrava, createPersonolize, getAllCities, getAllCountry, getAllGoals, getAllPrograms, getAllState, getDayoptionValues, getFechAlGoals, getPersonlisation, getPersonlisationForProgramID, getPrograms, getStravaURL, getUserBadges, getUsersProfile, updatePersonlise, updatePhoneVerificationStatus, updatePrfile,uploadsaveFile ,URL } from '../API/api-endpoint';
import Sidebar from '../components/Athelete/Sidebar';
import Header from '../components/Header';
import Swal from 'sweetalert2';
import { Modal } from '@mantine/core';
import { RecaptchaVerifier } from 'firebase/auth';
import { auth, sendOTPToUser, verifyOTPAndMerge } from '../API/firebase.config';
import { LoadingButton } from '@mui/lab';
import { showError, showSuccess } from '../components/Messages';
import { capitalizeFirstLetter, removeNullStrings, removeNullValues, validateDOB, validateMobileNumber } from '../utils/Resubale';
import { Tooltip } from 'antd';
import useMediaQuery from '@mui/material/useMediaQuery';

let daysInWeek = ["2", "3", "4", "5"]

const ProfilePage = ({ assignedCocahId }) => {

  const formik = useFormik({
    initialValues: { "firstname": "", mobile: "", dateofbirth: "" },


    validate: (values) => {
      let mobileValidationResult = ''
      let econtactValidationResult = ''
      // Validate the mobile number using the validateMobileNumber function
      const dobValidationResult = validateDOB(values.dateofbirth);
      if (values?.mobile) {
        mobileValidationResult = validateMobileNumber(values?.mobile?.toString());
      }
      if (values?.econtact) {
        econtactValidationResult = validateMobileNumber(values?.econtact?.toString());
      }
      const firstNameValidationResult = validateName(values?.firstname, 'First name');
      const lastNameValidationResult = validateName(values?.lastname, 'Last name');
      // Update the formik errors based on the mobile and DOB validation
      return {
        dateofbirth: dobValidationResult,
        mobile: mobileValidationResult.error,
        econtact: econtactValidationResult.error,
        firstname: firstNameValidationResult,
        lastname: lastNameValidationResult,
      };
    },
  });
  console.log("formik",formik?.values);
  const matches = useMediaQuery('(max-width:500px)');
  const validateName = (name, label) => {
    if (!name) {
      return `${label} is required`;
    }
    if (name.length < 1) {
      return `${label} must be at least 1 characters long`;
    }
    return '';
  };
  const [emailError, setEmailError] = useState('Email is required');
  const validateEmail = (email) => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let currentMail = currentUser.email
    console.log(currentMail,email,"old email");
    if (!email) {
      setEmailError('Email is required');
      return false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Invalid email address');
      return false;
    }
    else if (email == currentMail) {
      setEmailError('Please Enter New Email')
    }
    else {
      setEmailError('');
      return true;
    }
  };
  console.log("formik", formik?.errors);
  const [usersDetails, setUserDetails] = useState()
  const [isEditProfile, setIsEditProfile] = useState(false)
  const [isOpenGoal, setIsOpenGoal] = useState(false)
  const [isOpenChangeGoal, setIsOpenChangeGoal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [CountryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [fetchGoals, setFechGoals] = useState([]);
  const [fetchProgramList, setFetchProgramList] = useState([]);

  const [getDaysoptionList, setfetchDaysoptionList] = useState([]);

  const [hasFilled, setHasFilled] = useState(false);
  const [hasVerified, setHasVerified] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [oldEmail, setOldEmail] = useState(null);
  const [graphXLevel, setGraphXlevel] = useState();
  const [otp, setOtp] = useState("");
  const [hasChanged, setHasChanged] = useState(false);
  const [allBadges, setAllBadges] = useState();


  const [formGoalValue, setFormGoalValue] = useState(
    {
      goal: "",
      daysinweek: "",
      daysoption: "",
      program_id: "",
    }
  );
  console.log("formGoalValue", formGoalValue);
  const calculateAge = () => {
    const birthDate = moment(formik?.values?.dateofbirth, 'YYYY-MM-DD');
    const currentDate = moment();
    const age = currentDate.diff(birthDate, 'years');
    return age;
  };
  const [stravaURL, setstravaURL] = useState()
  console.log("stravaURL",stravaURL);
  
  const userId = localStorage.getItem('userId')

  const handleCountryChange = async (event, newValue) => {
    const countryId = newValue.id;
    formik.setFieldValue("country", newValue.id)
    // Fetch states based on the selected country ID
    fetchState(countryId)
  };
  const fetchState = async (countryId) => {
    const response = await getAllState(countryId);
    setStateList(response);
  }
  const handleStateChange = async (event, newValue) => {
    const stateID = newValue.id;
    formik.setFieldValue("state", stateID)

    fetchCity(formik, stateID)
    // Fetch states based on the selected country ID

  };

  const fetchCity = async (formik, stateID) => {
    const response = await getAllCities(formik?.values?.country, stateID);
    setCityList(response);
  }



  const getCountry = async () => {
    let response = await getAllCountry();

    setCountryList(response);
  };

  const getURLByLibraryId = async () => {
    // console.log("result",userId);

    if (userId !== null) {
      let result = await getStravaURL(userId);
      setstravaURL(result)
      // setWorkouts(result);

    }

  };
  const getBadgeOfUser = async () => {
    // console.log("result",userId);
    let result = await getUserBadges(userId);
    setAllBadges(result)
    console.log("result", result);



  };
  const fetchDGoals = async (id) => {
    setFechGoals([])
    let response = await getFechAlGoals(id);
    console.log("response", response);
    setFechGoals(response);
  };
  const fetchDefaultGoals = async (id) => {
    let response = await getFechAlGoals(id);
    formik.setFieldValue("goal", response[0]?.goal)
    // setFormGoalValue({ ...formGoalValue, goal: response.goals[0]?.goal, });
  }

  const fetchProgram = async () => {
    const response = await getAllPrograms()
    setFetchProgramList(response?.rows)
  }



  const fetchPersonlisationProfile = async () => {
    let userProgramID = localStorage.getItem("programID")
    let response = ""
    if (userProgramID) {
      response = await getPersonlisationForProgramID(userProgramID);
    } else {
      response = await getPersonlisation();

      if (!response || !response.status) {

        if (userProgramID === undefined) {
          userProgramID = null;
        }

        response = await getUsersProfile(userProgramID);
        let data = { ...response?.existingUserContacts, ...response?.personalization_profile, ...response?.user }
        let destructer = removeNullStrings(data)
        let valueChange = removeNullValues(destructer)
        if (valueChange.mobile && valueChange.mobile.length === 12) {
          valueChange.mobile = valueChange.mobile.slice(2); // Remove the first two characters
        }
        formik.setValues(valueChange)
      }
    }
    console.log("response",response);
    
    setFormGoalValue({ ...formGoalValue, program_id: response[0]?.program_id, id: response[0]?.id });

    fetchDaysOptuon(response[0]?.daysinweek, response[0]?.program_id);
    formik?.setFieldValue('daysoption', response[0]?.daysoption)
    fetchDGoals(response[0]?.program_id)
  };
  useEffect(() => {
    if (userId) {
      getURLByLibraryId();
      getBadgeOfUser()
    }
    getCountry();
    if (formik?.values?.country) {
      fetchState(formik?.values?.country)
    }
    if (formik?.values?.state) {
      fetchCity(formik, formik.values.state)
    }


  }, [assignedCocahId, formik?.values?.country, formik?.values?.state, formGoalValue?.daysinweek, graphXLevel]);
  useEffect(() => {
    fetchPersonlisationProfile()
    fetchProgram()

  }, []);

  const getUserDetails = async (activityID) => {
    let coachProgramID = localStorage.getItem("programID");
    const actProgram = await getPrograms();
    let filter = actProgram?.filter((data) => data.active == 1)
    let coachID = coachProgramID ? coachProgramID : filter[0]?.program_id;
    if (coachID) {

      const response = await getUsersProfile(coachID)
      let data = { ...response?.existingUserContacts, ...response?.personalization_profile, ...response?.user }
      let userProgramID = localStorage.getItem("programID")
      let destructer = removeNullStrings(data)
      let valueChange = removeNullValues(destructer)
      if (valueChange.mobile && valueChange.mobile.length === 12) {
        valueChange.mobile = valueChange.mobile.slice(2); // Remove the first two characters
      }
      formik.setValues(valueChange)
      // fetchPersonlisationProfile()
      setGraphXlevel(data?.daysinweek)
      let responseOfactivity = ""
      if (userProgramID) {
        responseOfactivity = await getPersonlisationForProgramID(activityID ? activityID : userProgramID);

      } else {
        responseOfactivity = await getPersonlisation();
        if (!responseOfactivity || !responseOfactivity.status) {

          if (userProgramID === undefined) {
            userProgramID = null;
          }

          responseOfactivity = await getUsersProfile(userProgramID);
          let data = { ...responseOfactivity?.existingUserContacts, ...responseOfactivity?.personalization_profile, ...responseOfactivity?.user }
          let destructer = removeNullStrings(data)
          let valueChange = removeNullValues(destructer)
          if (valueChange.mobile && valueChange.mobile.length === 12) {
            valueChange.mobile = valueChange.mobile.slice(2); // Remove the first two characters
          }
          formik.setValues(valueChange)
        }

      }

      formik.setFieldValue("daysoption", responseOfactivity[0]?.daysoption)
      formik.setFieldValue("daysinweek", responseOfactivity[0]?.daysinweek)
      // setFormGoalValue({ ...formGoalValue, daysinweek: responseOfactivity[0]?.daysinweek, });
      // setFormGoalValue({ ...formGoalValue, daysoption: responseOfactivity[0]?.daysoption, });


      const activityName = await getAllPrograms()
      let programID = responseOfactivity[0]?.program_id ? responseOfactivity[0]?.program_id : coachProgramID
      let goalName = destructer.goal ? destructer.goal : responseOfactivity[0].goal

      formik.setFieldValue("activity", responseOfactivity[0]?.program?.program_name)
      // setFormGoalValue({ ...formGoalValue, activities: responseOfactivity[0]?.program_id, id: responseOfactivity[0]?.id, goal: goalName });
      if (!goalName) {
        fetchDefaultGoals(responseOfactivity[0]?.program_id ? responseOfactivity[0]?.program_id : coachProgramID)
      }
      if (responseOfactivity[0]?.daysinweek) {
        fetchDaysOptuon(responseOfactivity[0]?.daysinweek, responseOfactivity[0]?.program_id ? responseOfactivity[0]?.program_id : coachProgramID);
      }

      // setFormGoalValue({ ...formGoalValue, daysoption: responseOfactivity[0]?.daysoption,daysinweek: responseOfactivity[0]?.daysinweek, });

console.log("responseOfactivity",responseOfactivity);

      const updatedValues = {
        ...formGoalValue,
        program_id: responseOfactivity[0]?.program_id,
        // id: responseOfactivity[0]?.id,
        goal: goalName,
        daysinweek: responseOfactivity[0]?.daysinweek,
        daysoption: responseOfactivity[0]?.daysoption,
        id:responseOfactivity[0]?.id
      };

      setFormGoalValue(updatedValues);

      setUserDetails(response)
    }
  }
  useEffect(() => {
    fetchDaysOptuon(formGoalValue?.daysinweek, formGoalValue?.program_id)
  }, [formGoalValue?.daysinweek])
  const fetchDaysOptuon = async (week, activity) => {
    const response = await getDayoptionValues(
      week ? week : formGoalValue?.daysinweek,
      activity ? activity : formGoalValue?.program_id
    );
    if (response) {
      setfetchDaysoptionList(response);
    }
  };

  useEffect(() => {
    getUserDetails()
  }, [])
  useEffect(() => {
    if (formik?.values?.profile && hasChanged) {
      const googleId = localStorage.getItem("google_id")
      updateUserPhotoURL(googleId, formik?.values?.profile)
      setHasChanged(true)
    }

  }, [formik?.values?.profile, hasChanged])


  const updateGoals = async () => {
    if (formGoalValue?.goal && formGoalValue?.daysinweek && formGoalValue?.daysoption) {
      let response = await updatePersonlise(formGoalValue); //its valdiate terms and conditions
      if (response?.status) {
        Swal.fire({
          title: "Success",
          text: "Profile Updated Successfully",
          icon: "success",
        });
        setIsOpenGoal(false)
        setIsOpenChangeGoal(false)
        getUserDetails(formGoalValue?.program_id)
        setFormGoalValue({
          ...formGoalValue, daysoption: "",
        })
      }
      // setIsOpenGoal
    } else {
      showError("Please fill all details")
    }
  }


  const updateUserPhotoURL = async (userUid, newPhotoURL) => {
    try {
      const db = getDatabase();

      const userRef = ref(db, `/users/${userUid}`);
      await update(userRef, { photoURL: newPhotoURL });


      console.log("nigixPhotoURL updated successfully", newPhotoURL);
    } catch (error) {
      console.error('Error updating photoURL:', error);
    }
  };

  const updateUserDetails = async (userUid, newDisplayName, newEmail) => {
    try {
      const db = getDatabase();

      const userRef = ref(db, `/users/${userUid}`);
      await update(userRef, {
        displayName: newDisplayName,
        email: newEmail
      });

      console.log("DisplayName and Email updated successfully", newDisplayName, newEmail);
    } catch (error) {
      console.error('Error updating DisplayName and Email:', error);
    }
  };



  //  submit profile 
  const handleOnSubmitForm = async (file) => {
    
      const showSuccess = (message) => {
        Swal.fire({
          title: "Success",
          text: message,
          icon: "success",
        });
      }
  

    const showErrorAndRedirect = (message) => {
      Swal.fire({
        title: "Error",
        text: message,
        icon: "error",
      });

    };
    try {
      if (!formik.errors?.dateofbirth && !formik.errors?.mobile?.incorrectLength && !formik.errors?.mobile?.incorrectFormat && !formik.errors?.econtact?.incorrectLength && !formik.errors?.econtact?.incorrectFormat && !formik.errors?.firstname && !formik.errors?.lastname) {
        let coachProgramID = localStorage.getItem("programID");
        let user_ID = localStorage.getItem("userId");
        const actProgram = await getPrograms(user_ID);
        let filter = actProgram?.filter((data) => data.active == 1)
        let coachID = filter[0]?.program_id ? filter[0]?.program_id : coachProgramID;
        if (coachID) {
          setIsLoading(true)
          const formData = new FormData();
          const { user_id, usercontact, ...userDataWithoutContact } = formik?.values;
          Object.keys(userDataWithoutContact).forEach((key) => {
            formData.append(key, userDataWithoutContact[key]);
          });
          formData.append('program_id', coachID);
          if (userDataWithoutContact?.id) {
            formData.append('user_id', userDataWithoutContact?.id);

          } else {
            formData.append('user_id', user_ID);

          }
          if (userDataWithoutContact?.dateofbirth) {

            formData.append('dob', userDataWithoutContact.dateofbirth);
          }
          if (formik?.values?.firstname && formik?.values?.email) {
            const googleId = localStorage.getItem("google_id")
            const fullName = `${formik?.values?.firstname} ${formik?.values?.lastname}`;
            updateUserDetails(googleId, fullName, formik?.values?.email);
          }
          if (file) {
            setHasChanged(true)
            formData.append('profile_image', file);
          }
          const Result = await updatePrfile(formData);
          if (Result.status) {
            showSuccess("Updation Successful");
            const currentUser = JSON.parse(localStorage.getItem("currentUser"));
            currentUser.email = formik?.values?.email;
            localStorage.setItem("currentUser", JSON.stringify(currentUser));
            setIsEditProfile(false)
            setHasVerified(false)
            setIsLoading(false)
            getUserDetails()
            let coachProgramID = localStorage.getItem("programID");

            const actProgram = await getPrograms();
            let filter = actProgram?.filter((data) => data.active == 1)
            let coachID = coachProgramID ? coachProgramID : filter[0]?.program_id;
            if (coachID) {
              const response = await getUsersProfile(coachID)
              let data = { ...response?.existingUserContacts, ...response?.personalization_profile, ...response?.user }
              // localStorage.setItm("refreshtoken");
              let destructer = removeNullStrings(data)
              let valueChange = removeNullValues(destructer)
              localStorage.setItem("profileImage", valueChange?.profile)
              formik.setValues(valueChange)
              setUserDetails(response)

            } else {
              showErrorAndRedirect(Result?.message)
            }
          }
        }
      }
      else {
        const errorFields = [];

        if (formik.errors?.firstname) {
          errorFields.push("First Name");
        }
        if (formik.errors?.lastname) {
          errorFields.push("Last Name");
        }
        if (formik.errors?.dateofbirth) {
          errorFields.push("Date of Birth");
        }
        if (formik.errors?.mobile?.incorrectLength || formik.errors?.mobile?.incorrectFormat) {
          errorFields.push("Mobile Number");
        }
        if (formik.errors?.econtact?.incorrectLength || formik.errors?.econtact?.incorrectFormat) {
          errorFields.push("Emergency Contact");
        }

        if (errorFields.length > 0) {
          const errorMessage = `Please fill all fields correctly: ${errorFields.join(", ")}`;
          showErrorAndRedirect(errorMessage);
        }
      }

    } catch (error) {
      setIsLoading(false)



    }
  };
  const handleDisconnect = async () => {
    const disconnectResponse = await DisconnectStrava()
    if (disconnectResponse?.status) {
      Swal.fire({
        title: "Sucess",
        text: disconnectResponse.message,
        icon: "success",
      });
      let coachProgramID = localStorage.getItem("programID");
      const actProgram = await getPrograms(assignedCocahId);
      let filter = actProgram?.filter((data) => data.active == 1)
      let coachID = coachProgramID ? coachProgramID : filter[0]?.program_id;
      if (coachID) {
        const response = await getUsersProfile(coachID, assignedCocahId)
        let data = { ...response?.existingUserContacts, ...response?.personalization_profile, ...response?.user }
        let destructer = removeNullStrings(data)
        let valueChange = removeNullValues(destructer)
        formik.setValues(valueChange)
        setUserDetails(response)
      }
    }
  }
  const handleConnect = () => {
    setTimeout(async () => {
      let coachProgramID = localStorage.getItem("programID");
      const actProgram = await getPrograms(assignedCocahId);
      let filter = actProgram?.filter((data) => data.active == 1)
      let coachID = filter[0]?.program_id ? filter[0]?.program_id : coachProgramID;
      if (coachID) {
        const response = await getUsersProfile(coachProgramID, coachID)
        let data = { ...response?.existingUserContacts, ...response?.personalization_profile, ...response?.user }
        let destructer = removeNullStrings(data)
        let valueChange = removeNullValues(destructer)
        formik.setValues(valueChange)
        setUserDetails(response)
      }
    }, 3000);
  }

  const handleSend = async (event) => {
    event.preventDefault();
    const validationResult = validateMobileNumber(formik?.values?.mobile);
    if (formik?.values?.mobile) {
      if (validationResult.isValid) {
        setIsLoading(true);
        let phone = `+91${formik?.values?.mobile}`
        try {
          // Create a reCAPTCHA verifier
          const recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha",
            {
              size: "invisible", // or "normal" if you want to show the reCAPTCHA box
            },
            auth
          );

          // Render and verify the reCAPTCHA
          const widgetId = await recaptchaVerifier.render();
          const event = await recaptchaVerifier.verify();

          // Send OTP to the phone number
          const result = await sendOTPToUser(phone, event);

          if (result.success) {
            Swal.fire({
              title: "Success",
              icon: "success",
            });
            setVerificationId(result.data);
            setIsLoading(false);
            setHasFilled(true);
          } else {
            Swal.fire({
              title: "Error!!",
              text: result.message,
              icon: "error",
            });
            setIsLoading(false);
          }
        } catch (error) {
          // Handle errors
          console.error("Error:", error);
          Swal.fire({
            title: "Error!!",
            text: "Something went wrong. Please try again later.",
            icon: "error",
          });
          setIsLoading(false);
        }
      } else {
        if (validationResult.error.invalidFormat) {
          showError("Invalid mobile number")
        }
        if (validationResult.error.incorrectLength) {
          showError("Mobile number must be 10 digits")
        }
      }

    } else {
      Swal.fire({
        title: "Error!!",
        text: "Please add mobile number in profile",
        icon: "error",
      });
    }

  };
  const verifyOtp = async (event) => {
    if (otp.length === 6) {
      try {
        setIsLoading(true);
        const result = await verifyOTPAndMerge(verificationId, otp);

        if (result.success) {
          Swal.fire({
            title: "Success!!",
            text: "OTP has been Verified Successfully",
            icon: "success",
          });
          setOldEmail(formik?.values?.email)
          setHasVerified(true)
          setHasFilled(false);
        } else {
          // setHasFilled(false);
          Swal.fire({
            title: "Error!!",
            text: result.message,
            icon: "error",
          });
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        // setHasFilled(false);

        Swal.fire({
          title: "Error!!",
          text: "An error occurred while verifying OTP",
          icon: "error",
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      Swal.fire({
        title: "Error!!",
        text: "Please enter a 6-digit OTP",
        icon: "error",
      });
    }
  };

  const handleChangeGoal = (event, newAlignment) => {
    setFormGoalValue({
      ...formGoalValue,
      daysinweek: Number(event.target.value)

    })

  };

  const control = {
    value: formGoalValue?.daysinweek,
    onChange: handleChangeGoal,
    exclusive: true,
  };
  let role_id = localStorage.getItem('roleID')

  const today = new Date();
  const twelveYearsAgo = new Date(today.getFullYear() - 14, today.getMonth(), today.getDate());
  const twelveYearsAgoString = twelveYearsAgo.toISOString().split("T")[0];
  return (
    <div className='mt-[65px] p-[20px] p0'>
      <Header />
      <div className='flex bg-[#f1f1f1] text-[18px] font16'>


        <div className={` mt20 ${( matches) ? "w-[40vw]" : "w-[25vw]"}`}>
          <Sidebar handleOnSubmitForm={handleOnSubmitForm} usersDetails={usersDetails} formik={formik} setIsEditProfile={setIsEditProfile} assignedCocahId={assignedCocahId} isEditProfile={isEditProfile} getUserDetails={getUserDetails} />
        </div>
        <div className={`flex w-[75vw]  mt20 ${matches ? "flex-col" : ""}`}>
        <div className={` justify-center items-center ${matches ? "w-[60vw]" : "w-[45vw]"}`} style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          paddingBottom: "20px",
          
        }}>
          <Box className=" w-[90%] h-[36%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4 mt-4">
            <b>{formik?.values?.firstname} {formik?.values?.lastname}</b>
            <p> {formik?.values?.gender ? capitalizeFirstLetter(formik?.values?.gender) : ""}</p>
            {formik?.values?.dateofbirth &&
              <div className='flexCol'>
                <p> Date Of Birth <strong>{moment(formik?.values?.dateofbirth).format("DD-MM-YYYY")}</strong></p>
                <p>Age <strong>{`${calculateAge()} Years`}</strong></p>
              </div>
            }


          </Box>
          {!isEditProfile &&

            <Box className=" w-[90%] h-[25%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4">
              <b>Address</b>
              <p>{formik?.values?.address1 || "NA"} {formik?.values?.address2 || ""}</p>
            </Box>
          }
          {!isEditProfile &&

            <Box className=" w-[90%] h-[25%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4">
              <b>Emergency Contact</b>
              <p>{formik?.values?.usercontact
                ?.econtact ? formik?.values?.usercontact
                ?.econtact : "NA"}</p>

            </Box>
          }
          {!isEditProfile && formik?.values?.role_id == 5 && formik?.values?.daysoption &&

            <Box className=" w-[90%]  bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4">
              <b>Schedule Details</b><br />
              <p><b>Program Name </b>  {formik?.values?.activity}</p>
              {formik?.values?.goal &&
                <p><b>Goal</b>  {formik?.values?.goal}</p>
              }
              <p><b>Days in week</b>  {formGoalValue?.daysinweek ? formGoalValue?.daysinweek : formik?.values?.daysinweek}</p>

              {isOpenGoal &&
                <>
                  <ToggleButtonGroup className='w-[45vw]' size="small" value={formGoalValue?.daysinweek} {...control} aria-label="Small sizes">
                    {fetchGoals?.availibledaysoption?.map((ele) => {
                      return (
                        <ToggleButton
                          style={{
                            backgroundColor: ele.rundays == formGoalValue.daysinweek ? '#E67E22' : 'inherit',
                            color: ele.rundays == formGoalValue.daysinweek ? 'white' : 'inherit',
                            width: "50px"
                          }}
                          value={ele?.rundays} key={ele?.rundays}>
                          {ele?.rundays}
                        </ToggleButton>
                      )
                    })}
                  </ToggleButtonGroup>
                </>
              }
              {isOpenGoal ? (
                <div className={getDaysoptionList?.length > 0 && "flex w-[100%] maxWidth50"} style={{ scrollbarColor: "#E67E22 lightGray ", scrollbarWidth: "thin", overflowX: "scroll" }}>
                  {getDaysoptionList?.map((ele) => {
                    return (
                      <div
                      className=' width150 font14'
                        style={
                          {
                            minWidth: "200px",
                            border: "1px solid gray",
                            margin: "10px",
                            borderRadius: "7px",
                            padding: "10px 0px 10px 10px",
                            boxShadow:
                              "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                          }
                        }
                      >
                        <div>
                          <div className="flex justify-between items-center">
                            <div>Monday </div>
                            {isOpenGoal &&
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                value={formGoalValue.daysoption}

                                onChange={(e) =>

                                  setFormGoalValue({
                                    ...formGoalValue,
                                    daysoption: e.target.value
                                  })
                                }
                              >
                                <FormControlLabel
                                  value={ele?.options}
                                  control={<Radio />}
                                />
                              </RadioGroup>
                            }

                          </div>
                          <div>{ele?.mondayworkout}</div>
                        </div>
                        &nbsp;
                        <div className=" mr-4">
                          <div>Tuesday</div>
                          <div>{ele?.tuesdayworkout}</div>
                        </div>
                        &nbsp;
                        <div className=" mr-4">
                          <div>Wednesday</div>
                          <div>{ele?.wednesdayworkout}</div>
                        </div>
                        &nbsp;
                        <div className=" mr-4">
                          <div>Thursday</div>
                          <div>{ele?.thursdayworkout}</div>
                        </div>
                        &nbsp;
                        <div className=" mr-4">
                          <div>Friday</div>
                          <div>{ele?.fridayworkout}</div>
                        </div>
                        &nbsp;
                        <div className=" mr-4">
                          <div>Saturday</div>
                          <div>{ele?.saturdayworkout}</div>
                        </div>
                        &nbsp;
                        <div className=" mr-4">
                          <div>Sunday</div>
                          <div>{ele?.sundayworkout}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className={getDaysoptionList?.length > 0 && "flex w-[100%] overflow-scroll"}>
                  {formik?.values?.daysoption &&
                    <>
                      {getDaysoptionList?.filter((ele) => {
                        return ele.options == formik?.values?.daysoption;
                      }).map((ele) => {
                        return (
                          <div
                            style={
                              {
                                minWidth: "200px",
                                border: "1px solid gray",
                                margin: "10px",
                                borderRadius: "7px",
                                padding: "10px 0px 10px 10px",
                                boxShadow:
                                  "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                              }
                            }
                          >
                            <div>
                              <div className="flex justify-between items-center">
                                <div>Monday </div>
                                {isOpenGoal &&
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    value={formGoalValue.daysoption}

                                    onChange={(e) =>

                                      setFormGoalValue({
                                        ...formGoalValue,
                                        daysoption: e.target.value
                                      })
                                    }
                                  >
                                    <FormControlLabel
                                      value={ele?.options}
                                      control={<Radio />}
                                    />
                                  </RadioGroup>
                                }

                              </div>
                              <div>{ele?.mondayworkout}</div>
                            </div>
                            &nbsp;
                            <div className=" mr-4">
                              <div>Tuesday</div>
                              <div>{ele?.tuesdayworkout}</div>
                            </div>
                            &nbsp;
                            <div className=" mr-4">
                              <div>Wednesday</div>
                              <div>{ele?.wednesdayworkout}</div>
                            </div>
                            &nbsp;
                            <div className=" mr-4">
                              <div>Thursday</div>
                              <div>{ele?.thursdayworkout}</div>
                            </div>
                            &nbsp;
                            <div className=" mr-4">
                              <div>Friday</div>
                              <div>{ele?.fridayworkout}</div>
                            </div>
                            &nbsp;
                            <div className=" mr-4">
                              <div>Saturday</div>
                              <div>{ele?.saturdayworkout}</div>
                            </div>
                            &nbsp;
                            <div className=" mr-4">
                              <div>Sunday</div>
                              <div>{ele?.sundayworkout}</div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  }

                </div>
              )}



              <div className='mt10' style={{ display: "flex", justifyContent: "end" }}>
                {isOpenGoal ?
                  <Button variant='contained' onClick={updateGoals}>Submit</Button>
                  :
                  <Button variant='contained' onClick={() => setIsOpenGoal(true)}>Change Schedule</Button>
                }

              </div>
            </Box>
          }
          {isEditProfile &&

            <Box className=" w-[90%]  bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4">
              <b>Personal Information</b>
              <CardContent>
                <Grid container>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <label variant="h6">First Name</label>
                      <TextField
                        fullWidth
                        id="filled-basic" variant="filled"
                        placeholder="First Name"
                        error={formik.errors?.firstname && formik.errors?.firstname}
                        helperText={
                          formik.errors.firstname && formik.errors.firstname
                        }
                        type="text"
                        name="firstname"
                        value={formik?.values?.firstname}
                        onChange={formik.handleChange}
                        onInput={(e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, ''); // Restrict input to alphabetic characters
    e.target.value = e.target.value.replace(/\b\w/g, (char) => char.toUpperCase());
    if (e.target.value.length > 20) {
      e.target.value = e.target.value.slice(0, 20); // Trim the input to 20 characters
    }
  }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label variant="h6">Last Name</label>
                      <TextField
                        fullWidth
                        id="filled-basic" variant="filled"
                        placeholder="Last Name"
                        error={formik.errors?.lastname && formik.errors?.lastname}
                        helperText={
                          formik.errors.lastname && formik.errors.lastname
                        }
                        type="text"
                        name="lastname"
                        value={formik?.values?.lastname}
                        onChange={formik.handleChange}
                        onInput={(e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z]/g, ''); // Restrict input to alphabetic characters
    e.target.value = e.target.value.replace(/\b\w/g, (char) => char.toUpperCase());
    if (e.target.value.length > 20) {
      e.target.value = e.target.value.slice(0, 20); // Trim the input to 20 characters
    }
  }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <label variant="h6">DOB</label>
                      <TextField
                        fullWidth
                        id="filled-basic" variant="filled"
                        placeholder="Workout Title"
                        error={formik.errors?.dateofbirth && formik.errors?.dateofbirth}
                        helperText={
                          formik.errors.dateofbirth && formik.errors.dateofbirth
                        }
                        type="date"
                        name="dateofbirth"
                        inputProps={{
                          max: twelveYearsAgoString, placeholder: "DD-MM-YYYY*" // Set max attribute to today's date
                        }}
                        value={formik?.values?.dateofbirth}
                        onChange={formik.handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <label variant="h6">Email</label>
                      <TextField
                        fullWidth
                        id="filled-basic" variant="filled"
                        placeholder="Workout Title"
                        type="text"
                        disabled
                        name="email"
                        value={formik?.values?.email}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} >
                      <label variant="h6">Qualification</label>
                      <TextField
                        fullWidth
                        id="filled-basic" variant="filled"
                        placeholder="Qualification"
                        type="text"
                        name="qualification"
                        value={formik?.values?.qualification}
                        onChange={formik.handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} >
                      <label variant="h6">Gender</label>
                      <Select
                        fullWidth
                        id="filled-basic" variant="filled"
                        // style={{color:"white"}}
                        placeholder="123"
                        name="gender"
                        value={formik.values.gender ? formik.values.gender : ""}
                        onChange={formik.handleChange}
                        // id="form-layouts-separator-select"
                        labelId="form-layouts-separator-select-label"
                        input={
                          <OutlinedInput id="select-multiple-language" />
                        }
                      >
                        <MenuItem value={"male"}>Male</MenuItem>
                        <MenuItem value={"female"}>Female</MenuItem>

                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} >
                      <label variant="h6">Occupation</label>
                      <TextField
                        fullWidth
                        id="filled-basic" variant="filled"
                        placeholder="Occupation"
                        type="text"
                        name="occupation"
                        value={formik?.values?.occupation}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} >
                      <label variant="h6">Organization</label>
                      <TextField
                        fullWidth
                        id="filled-basic" variant="filled"
                        placeholder="Organization"
                        type="text"
                        name="organization"
                        value={formik?.values?.organization}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} >
                    </Grid>
                    <Grid item xs={12} sm={12} >
                      <b>Address Information</b>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                      <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                        {CountryList?.length > 0 &&
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={CountryList}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.id == value.id} // This is crucial for selecting the correct pre-fetch value
                            value={CountryList.find((option) => option.id == formik.values.country) || null}
                            onChange={handleCountryChange}
                            renderInput={(params) => <TextField {...params} id="filled-basic" variant="filled" label={!formik.values.country && "Country"} />}
                          />
                        }

                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                      <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={stateList}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) => option.id == value.id} // This is crucial for selecting the correct pre-fetch value
                          value={stateList.find((option) => option.id == formik.values.state) || null}
                          onChange={handleStateChange}
                          renderInput={(params) => <TextField {...params} id="filled-basic" variant="filled" label={!formik.values.state && "State"} />}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                      <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={cityList}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) => option.id == value.id} // This is crucial for selecting the correct pre-fetch value
                          value={cityList.find((option) => option.id == formik.values.city) || null}
                          onChange={(e, newValue) => formik.setFieldValue("city", newValue.id)}
                          renderInput={(params) => <TextField {...params} id="filled-basic" variant="filled" label={!formik.values.city && "City"} />}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} ></Grid>
                    <Grid item xs={12} sm={4} >
                      <label variant="h6">Address</label>
                      <TextField
                        fullWidth
                        id="filled-basic" variant="filled"
                        placeholder="Address"
                        type="text"
                        name="address1"
                        value={formik?.values?.address1}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} >
                      <label variant="h6">Address2</label>
                      <TextField
                        fullWidth
                        id="filled-basic" variant="filled"
                        placeholder="address2"
                        type="text"
                        name="address2"
                        value={formik?.values?.address2}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Box>
          }


        </div>
        <div className={`p-4 ${matches ? "w-[60vw] mt-5" : "w-[30vw]"}`}>
          <Box className=" w-[100%] min-h-[40%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4">
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>

                <b>Contact</b>
                <p className='flexRow itemsCenter'><PhoneIcon className='text-[#E67E22] mb-2 mt-2  ' />&nbsp; <strong>

                  {isEditProfile ? (
                    <TextField
                      id="filled-basic" variant="filled"
                      placeholder="Contact"
                      type="text"
                      name="mobile"
                      error={formik.errors.mobile?.incorrectLength || formik.errors.mobile?.invalidFormat}
                      helperText={
                        formik.errors.mobile?.incorrectLength ? 'Mobile Number Should Be Of 10 Digits' :
                          formik.errors.mobile?.invalidFormat ? 'Invalid format' :
                            undefined
                      }
                      value={formik?.values?.mobile}
                      onChange={(event) => {
                        const { value } = event.target;
                        const isValid = /^[0-9]*$/.test(value) && value.length <= 10;
                        if (isValid) {
                          formik.handleChange(event);
                        }
                      }}
                    />
                  ) : (
                    <>{formik?.values?.mobile ? formik?.values?.mobile : "NA"}</>
                  )}
                </strong></p> &nbsp;


                <p  className='flexRow itemsCenter'><PhoneIcon className='text-[#E67E22] mb-2 mt-2  ' />&nbsp;<strong>

                  {isEditProfile ? (
                    <TextField
                      id="filled-basic" variant="filled"
                      placeholder="Emergency Contact"
                      type="text"
                      name="econtact"
                      value={formik?.values?.econtact}
                      error={formik.errors.econtact?.incorrectLength || formik.errors.econtact?.invalidFormat}
                      helperText={
                        formik.errors.econtact?.incorrectLength ? 'Emergency Contact Should Be Of 10 Digits' :
                          formik.errors.econtact?.invalidFormat ? 'Invalid format' :
                            undefined
                      }
                      onChange={(event) => {
                        const { value } = event.target;
                        const isValid = /^[0-9]*$/.test(value) && value.length <= 10;
                        if (isValid) {
                          formik.handleChange(event);
                        }
                      }}
                    />
                  ) : (
                    <>  E-Contact {formik?.values?.econtact ? formik?.values?.econtact : "NA"}</>
                  )}
                </strong></p>
                &nbsp;
                {isEditProfile ? (
                  <p className='flexRow itemsCenter'><EmailIcon className='text-[#E67E22]' /> &nbsp;<strong>
                    <TextField
                      id="filled-basic" variant="filled"
                      placeholder="Contact"
                      type="text"
                      name="email"
                      disabled
                      value={formik?.values?.email}
                      onChange={formik.handleChange}
                    />
                  </strong></p>

                ) : (

                  <p  className='flexRow itemsCenter' style={{width:"90%", wordBreak:"break-all"}}><EmailIcon className='text-[#E67E22]' /> &nbsp;<strong>{formik?.values?.email}</strong></p>
                )}
                &nbsp;
                {isEditProfile ? (
                  <p className='flex rounded-md'>

                    <img
                      src="https://play-lh.googleusercontent.com/j-ZV144PlVuTVsLuBzIKyEw9CbFnmWw9ku2NJ1ef0gZJh-iiIN1nrNPmAtvgAteyDqU"
                      alt="..."
                      className="w-6 h-6  border-2 cursor-pointer"
                    />
                    &nbsp;<strong>

                      <TextField
                        id="filled-basic" variant="filled"
                        placeholder="Strava Name"
                        type="text"
                        name="strava_username"
                        value={formik?.values?.strava_username}
                        onChange={formik.handleChange}
                      />
                    </strong></p>
                ) : (
                  <p className='flex rounded-md  itemsCenter'  style={{ wordBreak: 'break-word' }}>

                    <img
                      src="https://play-lh.googleusercontent.com/j-ZV144PlVuTVsLuBzIKyEw9CbFnmWw9ku2NJ1ef0gZJh-iiIN1nrNPmAtvgAteyDqU"
                      alt="..."
                      className="w-6 h-6  border-2 cursor-pointer"
                    />
                    &nbsp;<strong>
                      Strava Name: {formik?.values?.strava_username ? formik?.values?.strava_username : "NA"}
                    </strong></p>

                )}
                &nbsp;
                <p><InstagramIcon className='text-[#E4405F] mb-2 mt-2  ' />&nbsp; <strong>

                  {isEditProfile ? (
                    <TextField
                      id="filled-basic" variant="filled"
                      placeholder="Insta"
                      type="text"
                      name="insta"
                      value={formik?.values?.insta}
                      onChange={formik.handleChange}
                    />
                  ) : (

                    <>{formik?.values?.insta ? formik?.values?.insta : "NA"}</>
                  )}
                </strong></p>
                &nbsp;
                <p><FacebookIcon className='text-[blue] mb-2 mt-2  ' />&nbsp; <strong>

                  {isEditProfile ? (
                    <TextField
                      id="filled-basic" variant="filled"
                      placeholder="Facebook"
                      type="text"
                      name="facebook"
                      value={formik?.values?.facebook}
                      onChange={formik.handleChange}
                    />
                  ) : (
                    <>{formik?.values?.facebook ? formik?.values?.facebook : "NA"}</>
                  )}
                </strong></p>
              </div>
              {role_id == 5 &&
              <div style={{ border: "2px solid gray", width: "55%", height: "250px" }}>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"30px",background:"gray"}}>
                  
                 <p style={{fontSize:"14px",fontWeight:600}}> Badges won</p>
                </div>
                {allBadges?.length>0 ?(
                  <div style={{padding:"10px",display:"flex"}}>
                  {allBadges?.map((bedges) => {
                    return (
                      <Tooltip placement="bottom"  title={bedges?.challenge}>
         
                        <img
                          src={bedges?.badge?`${URL}/static/public/userimages/${bedges?.badge}`:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbQIfgvrUcDEY90ZrIVSMPYX4hvjFe963pHSxaVK3fuw&s"}
                          alt="..."
                          className="w-8 h-8  border-2 rounded-full"
                          />
                           </Tooltip>
                        )
                      })}
  
                      </div>
                ):(
                  <div style={{padding:"10px 4px 0px 10px"}}>
                    Your earned badges would come here
                  </div>
                )}
                
              </div>
}
            </div>
          </Box>
          <Box className=" w-[100%] m-h-[30%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4 mt-4">
            <div> <b>Connect <InfoOutlinedIcon /> </b></div>
            <p>Strava&nbsp;</p>
            <p><strong>Status:</strong> &nbsp;<strong>{formik?.values?.strava_id ? "Connected" : "Disconnected"}</strong></p>

            <div className='Flex flex-col'>
            <Button variant='contained' style={{ fontSize: "12px",backgroundColor:"#E67E22" }}>
              {formik?.values?.strava_id ? <a onClick={() => handleDisconnect()}>Disconnect</a> : <a href={stravaURL?.url} target='_blank' onClick={() => handleConnect()}>Connect with strava</a>}


            </Button> <br />
            <div className='mt-2 mb-2 flex '>
              {role_id == 5 &&

                <Button className='text-sm' style={{ fontSize: "12px", backgroundColor: "#E67E22" }} variant='contained' onClick={() => setIsOpenChangeGoal(true)}>
                  <a >Change Goal</a>
                </Button>
              }
              &nbsp; &nbsp;

              <form onSubmit={handleSend}>
                {/* Your form components */}
                <Button className='text-sm' style={{ fontSize: "12px", backgroundColor: "#E67E22" }} variant='contained' type="submit">Change Email</Button>
                {/* Invisible reCAPTCHA */}
                <div id="recaptcha" />
              </form>
              </div>
            </div>
          </Box>
        </div>
        </div>
      </div>
      <Modal className='changeGoalModal goalModal' opened={isOpenChangeGoal} onClose={() => setIsOpenChangeGoal(false)} title="Change Goals">
        <div className="w-full flex flex-col gap-4 items-start">
          <div
            style={{
              borderRadius: "10px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
            className="flow-running-form"
          >

            <div className="flow-flex-input" style={{ display: "block" }}>
              <label style={{ width: "30vw" }}>Program Name</label>
              <select
                className="running-step-input hideDropdown"
                name="Country"
                id="country"
                value={formGoalValue.program_id}
                disabled
                onChange={(e) => {
                  fetchDGoals(e.target.value)
                  setFormGoalValue({ ...formGoalValue, program_id: e.target.value })
                  localStorage.setItem('programID', e.target.value)

                }
                }
              ><option value="" disabled>
                  Select Program
                </option>
                {fetchProgramList?.map((ele, index) => (
                  <option key={index} value={ele?.program_id} disabled>
                    {ele?.program_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flow-flex-input" style={{ display: "block" }}>
              <label style={{ width: "30vw" }}>Goals<span className="text-[red]">*</span></label>
              <select
                className="running-step-input"
                name="Country"
                id="country"
                value={formGoalValue.goal}
                onChange={(e) =>
                  setFormGoalValue({ ...formGoalValue, goal: e.target.value })
                }
              ><option value="" disabled>
                  Select Goals
                </option>
                {fetchGoals?.goals?.map((ele, index) => (
                  <option key={index} value={ele?.goal}>
                    {ele?.goal}
                  </option>
                ))}
              </select>
            </div>
            <div className="flow-flex-input" style={{ display: "block" }}>
              <label style={{ width: "11vw" }}>Days in week<span className="text-[red]">*</span></label>
              <select
                className="running-step-input"
                name="Country"
                id="country"
                value={formGoalValue.daysinweek}
                onChange={(e) =>
                  setFormGoalValue({
                    ...formGoalValue,
                    daysinweek: e.target.value,
                  })
                }
              >
                <option value="" disabled>
                  Select Days
                </option>
                {(formGoalValue.program_id == 5) ?

                  <>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </>
                  :

                  <>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </>

                }

              </select>
            </div>
            <div className={getDaysoptionList?.length > 0 && "flex w-[100%]"} style={{ scrollbarColor: "#E67E22 lightGray ", scrollbarWidth: "thin", overflowX: "scroll" }}>
              {getDaysoptionList?.map((ele) => {
                return (
                  <div
                    style={{
                      minWidth: "200px",
                      border: "1px solid gray",
                      margin: "10px",
                      borderRadius: "7px",
                      padding: "10px 0px 10px 10px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                    }}
                  >
                    <div>
                      <div className="flex justify-between items-center">
                        <div style={{ display: "flex", flexDirection: "column" }}>Monday
                          <div>{ele?.mondayworkout}</div>
                        </div>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          value={formGoalValue.daysoption}

                          onChange={(e) =>
                            setFormGoalValue({
                              ...formGoalValue,
                              daysoption: e.target.value,
                            })
                          }
                        >
                          <FormControlLabel
                            value={ele?.options}
                            control={<Radio />}
                          />
                        </RadioGroup>
                      </div>

                    </div>
                    &nbsp;
                    <div className=" mr-4">
                      <div>Tuesday</div>
                      <div>{ele?.tuesdayworkout}</div>
                    </div>
                    &nbsp;
                    <div className=" mr-4">
                      <div>Wednesday</div>
                      <div>{ele?.wednesdayworkout}</div>
                    </div>
                    &nbsp;
                    <div className=" mr-4">
                      <div>Thursday</div>
                      <div>{ele?.thursdayworkout}</div>
                    </div>
                    &nbsp;
                    <div className=" mr-4">
                      <div>Friday</div>
                      <div>{ele?.fridayworkout}</div>
                    </div>
                    &nbsp;
                    <div className=" mr-4">
                      <div>Saturday</div>
                      <div>{ele?.saturdayworkout}</div>
                    </div>
                    &nbsp;
                    <div className=" mr-4">
                      <div>Sunday</div>
                      <div>{ele?.sundayworkout}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-2 flex gap-2 item-center flex-wrap">
            <button onClick={updateGoals} className=" orangeBg text-sm font-regular px-4 py-2 rounded-sm text-white">
              Apply
            </button>
            <button onClick={() => {
              setIsOpenChangeGoal(false)
            
              // setfetchDaysoptionList([])
            }} className=" orangeBg text-sm font-regular px-4 py-2 rounded-sm text-white">
              Clear
            </button>
          </div>
        </div>
      </Modal>
      {hasFilled &&
        (<Modal opened={hasFilled} onClose={() => setHasFilled(false)} >
          <Card >
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ padding: "20px" }} variant="h6" component="div">
                An OTP has been sent to your mobile, please enter it below to verify your mobile number.
              </Typography>
              <TextField
                sx={{ width: "240px" }}
                variant="outlined"
                type="number"
                label="OTP "
                value={otp}
                onChange={(event) => setOtp(event.target.value)}
              />
              <LoadingButton
                variant="contained"
                sx={{
                  width: "240px",
                  marginTop: "20px",
                  // color: "white",
                  // backgroundColor: "rgb(145, 85, 253)",
                  color: "white",
                  backgroundColor: "#2563EB",
                }}
                onClick={verifyOtp}
                loading={isLoading}
                loadingPosition="start"
              >
                Verify Otp
              </LoadingButton>
            </CardContent>
          </Card>
        </Modal>
        )}
      {hasVerified &&
        (<Modal opened={hasVerified} onClose={() => setHasVerified(false)} >
          <Card >
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ padding: "20px" }} variant="h6" component="div">
                Change your old email
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={oldEmail}
                disabled
              />
              &nbsp;
              <TextField
                fullWidth
                variant="outlined"
                name="email"
                // value={formik?.values?.email}
                // value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={() => validateEmail(formik.values.email)}
                error={Boolean(emailError)}
                helperText={emailError}
              />
              <LoadingButton
                variant="contained"
                sx={{
                  width: "240px",
                  marginTop: "20px",
                  // color: "white",
                  // backgroundColor: "rgb(145, 85, 253)",
                  color: "white",
                  backgroundColor: "#2563EB",
                }}
                onClick={() => {
                  if (!emailError) {
                    handleOnSubmitForm()
                  }
                  else {
                    Swal.fire({
                      title: "Error",
                      text: emailError,
                      icon: "error",
                    });
                  }

                }
                }
                loading={isLoading}
                loadingPosition="start"
              // disabled={Boolean(emailError) || isLoading}
              >
                Verify Email
              </LoadingButton>
            </CardContent>
          </Card>
        </Modal>
        )}
    </div>

  )
}



export default ProfilePage
